
import { Unescaper } from "./services/Unescaper"
import { Vue, Options } from "vue-class-component"

@Options({
  components: {}
})
export default class App extends Vue {
  inputText: string = ""
  escapedText: string = ""

  inputChanged() {
    this.escapedText = Unescaper.unescape(this.inputText)
  }
}
